html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
  height: 100vh;
}

*, *:before, *:after {
  box-sizing: inherit;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  &:hover, &:active, &:focus{
    text-decoration: none;
  }
}

body {
  &.modal-open{
    @media (max-width: 768px) {
      height: 100vh;
      overflow: hidden;
    }
  }
}

.no-padding{
  padding: 0;
}