@import 'icon-variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?g09xds')
    format('woff');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?g09xds')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?g09xds#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?g09xds')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?g09xds##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  //line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pdf:before {
  content: "\e970";
  color: #f2284f;
}
.icon-download3 .path1:before {
  content: "\e96b";
  color: none;
  opacity: 0.428;
}
.icon-download3 .path2:before {
  content: "\e96c";
  margin-left: -1.4541015625em;
  color: rgb(2, 2, 2);
}
.icon-download3 .path3:before {
  content: "\e96d";
  margin-left: -1.4541015625em;
  color: rgb(255, 255, 255);
}
.icon-download3 .path4:before {
  content: "\e96e";
  margin-left: -1.4541015625em;
  color: rgb(240, 65, 99);
}
.icon-download3 .path5:before {
  content: "\e96f";
  margin-left: -1.4541015625em;
  color: none;
}
.icon-excel .path1:before {
  content: "\e957";
  color: rgb(49, 198, 132);
}
.icon-excel .path2:before {
  content: "\e958";
  margin-left: -1.1337890625em;
  color: rgb(33, 166, 100);
}
.icon-excel .path3:before {
  content: "\e959";
  margin-left: -1.1337890625em;
  color: rgb(14, 121, 65);
}
.icon-excel .path4:before {
  content: "\e95a";
  margin-left: -1.1337890625em;
  color: rgb(33, 162, 98);
}
.icon-excel .path5:before {
  content: "\e95b";
  margin-left: -1.1337890625em;
  color: rgb(16, 121, 65);
}
.icon-excel .path6:before {
  content: "\e95c";
  margin-left: -1.1337890625em;
  color: rgb(16, 93, 49);
}
.icon-excel .path7:before {
  content: "\e95d";
  margin-left: -1.1337890625em;
  color: rgb(16, 93, 47);
}
.icon-excel .path8:before {
  content: "\e95e";
  margin-left: -1.1337890625em;
  color: rgb(16, 134, 74);
}
.icon-excel .path9:before {
  content: "\e95f";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.icon-powerpoint .path1:before {
  content: "\e960";
  color: rgb(241, 108, 73);
}
.icon-powerpoint .path2:before {
  content: "\e961";
  margin-left: -1.1337890625em;
  color: rgb(255, 143, 106);
}
.icon-powerpoint .path3:before {
  content: "\e962";
  margin-left: -1.1337890625em;
  color: rgb(215, 80, 50);
}
.icon-powerpoint .path4:before {
  content: "\e963";
  margin-left: -1.1337890625em;
  color: rgb(189, 56, 25);
}
.icon-powerpoint .path5:before {
  content: "\e964";
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.icon-word .path1:before {
  content: "\e965";
  color: rgb(65, 166, 238);
}
.icon-word .path2:before {
  content: "\e966";
  margin-left: -1.2138671875em;
  color: rgb(41, 125, 215);
}
.icon-word .path3:before {
  content: "\e967";
  margin-left: -1.2138671875em;
  color: rgb(16, 90, 189);
}
.icon-word .path4:before {
  content: "\e968";
  margin-left: -1.2138671875em;
  color: rgb(7, 61, 147);
}
.icon-word .path5:before {
  content: "\e969";
  margin-left: -1.2138671875em;
  color: rgb(23, 102, 207);
}
.icon-word .path6:before {
  content: "\e96a";
  margin-left: -1.2138671875em;
  color: rgb(255, 255, 255);
}
.icon-lock-rounded:before {
  content: "\e955";
}
.icon-download:before {
  content: "\e956";
}
.icon-credit-cards-amex .path1:before {
  content: "\e900";
  color: rgb(42, 160, 208);
}
.icon-credit-cards-amex .path2:before {
  content: "\e901";
  margin-left: -1.5em;
  color: rgb(42, 160, 208);
}
.icon-credit-cards-amex .path3:before {
  content: "\e902";
  margin-left: -1.5em;
  color: rgb(42, 160, 208);
}
.icon-credit-cards-amex .path4:before {
  content: "\e903";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-credit-cards-amex .path5:before {
  content: "\e904";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-credit-cards-amex .path6:before {
  content: "\e905";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
  opacity: 0.0500;
}
.icon-credit-cards-mastercard .path1:before {
  content: "\e906";
  color: rgb(67, 97, 195);
}
.icon-credit-cards-mastercard .path2:before {
  content: "\e907";
  margin-left: -1.36328125em;
  color: rgb(255, 175, 56);
}
.icon-credit-cards-mastercard .path3:before {
  content: "\e908";
  margin-left: -1.36328125em;
  color: rgb(255, 175, 56);
}
.icon-credit-cards-mastercard .path4:before {
  content: "\e909";
  margin-left: -1.36328125em;
  color: rgb(235, 54, 54);
}
.icon-credit-cards-mastercard .path5:before {
  content: "\e90a";
  margin-left: -1.36328125em;
  color: rgb(255, 175, 56);
}
.icon-credit-cards-mastercard .path6:before {
  content: "\e90b";
  margin-left: -1.36328125em;
  color: rgb(255, 255, 255);
  opacity: 0.0500;
}
.icon-credit-cards-visa .path1:before {
  content: "\e90c";
  color: rgb(249, 249, 249);
}
.icon-credit-cards-visa .path2:before {
  content: "\e90d";
  margin-left: -1.5em;
  color: rgb(9, 89, 158);
}
.icon-credit-cards-visa .path3:before {
  content: "\e90e";
  margin-left: -1.5em;
  color: rgb(229, 151, 3);
}
.icon-credit-cards-visa .path4:before {
  content: "\e90f";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
  opacity: 0.0500;
}
.icon-credit-cards-visa .path5:before {
  content: "\e910";
  margin-left: -1.5em;
  color: rgb(0, 88, 159);
}
.icon-credit-cards-visa .path6:before {
  content: "\e911";
  margin-left: -1.5em;
  color: rgb(249, 165, 26);
}
.icon-lock-reverse:before {
  content: "\e913";
}
.icon-icona-check-rounded:before {
  content: "\e914";
}
.icon-icona-rimuovi:before {
  content: "\e915";
}
.icon-icona-rimuovi-copy-1:before {
  content: "\e916";
}
.icon-icona-collection-copy-1:before {
  content: "\e917";
  color: #125C87;
}
.icon-icona-aggiungi:before {
  content: "\e918";
}
.icon-collection:before {
  content: "\e919";
}
.icon-commenti:before {
  content: "\e91a";
}
.icon-check:before {
  content: "\e91b";
}
.icon-speak:before {
  content: "\e91c";
}
.icon-isp-logo:before {
  content: "\e91d";
}
.icon-exit:before {
  content: "\e91e";
}
.icon-calendar:before {
  content: "\e91f";
}
.icon-hamburger:before {
  content: "\e920";
}
.icon-star:before {
  content: "\e921";
}
.icon-clock1:before {
  content: "\e922";
}
.icon-close-x:before {
  content: "\e923";
}
.icon-modifica:before {
  content: "\e924";
}
.icon-notifica:before {
  content: "\e926";
}
.icon-ricerca:before {
  content: "\e927";
}
.icon-visualizzazioni:before {
  content: "\e928";
}
.icon-heart:before {
  content: "\e929";
}
.icon-app-store:before {
  content: "\e92a";
}
.icon-uniE913:before {
  content: "\e92b";
}
.icon-uniE914:before {
  content: "\e92c";
}
.icon-uniE915:before {
  content: "\e92d";
}
.icon-decrease:before {
  content: "\e92e";
}
.icon-uniE917:before {
  content: "\e92f";
}
.icon-grow:before {
  content: "\e930";
}
.icon-help:before {
  content: "\e931";
}
.icon-info:before {
  content: "\e932";
}
.icon-uniE91B:before {
  content: "\e933";
}
.icon-uniE91E:before {
  content: "\e934";
}
.icon-uniE91F:before {
  content: "\e935";
}
.icon-uniE920:before {
  content: "\e936";
}
.icon-uniE921:before {
  content: "\e937";
}
.icon-uniE922:before {
  content: "\e938";
}
.icon-uniE923:before {
  content: "\e939";
}
.icon-uniE924:before {
  content: "\e93a";
}
.icon-uniE925:before {
  content: "\e93b";
}
.icon-uniE927:before {
  content: "\e93d";
}
.icon-uniE928:before {
  content: "\e93e";
}
.icon-play:before {
  content: "\e93f";
}
.icon-arrow-right:before {
  content: "\e940";
}
.icon-btn-arrow:before {
  content: "\e941";
}
.icon-filter:before {
  content: "\e942";
}
.icon-plus:before {
  content: "\e943";
}
.icon-foto:before {
  content: "\e944";
}
.icon-griglia:before {
  content: "\e945";
}
.icon-lista:before {
  content: "\e946";
}
.icon-icona-chevron-giu-active:before {
  content: "\e952";
}
.icon-icona-like:before {
  content: "\e947";
}
.icon-uniE937:before {
  content: "\e948";
}
.icon-uniE938:before {
  content: "\e949";
}
.icon-bookmark:before {
  content: "\e94a";
}
.icon-icona-play:before {
  content: "\e94b";
}
.icon-icona-lucchetto:before {
  content: "\e94c";
}
.icon-icona-allega:before {
  content: "\e94d";
}
.icon-icona-add-user1:before {
  content: "\e94e";
}
.icon-uniE940:before {
  content: "\e94f";
}
.icon-icona-salva-attivo:before {
  content: "\e951";
}
.icon-icona-elimina:before {
  content: "\e99a";
}
.icon-clock:before {
  content: "\e954";
}
.icon-note:before {
  content: "\e925";
}
.icon-manager:before {
  content: "\e953";
}
.icon-send:before {
  content: "\e93c";
}
.icon-icona-info-active:before {
  content: "\e912";
}
.icon-icona-salva-active:before {
  content: "\e950";
}

.icon-premium:before {
  content: "\e971";
}
